import React from 'react';

import { ReactComponent as EC2Icon } from '../../../src/svgs/cloudHostjane/ec2.svg';
import { ReactComponent as AwsIcon } from '../../../src/svgs/cloudHostjane/aws.svg';
import { ReactComponent as CpanelIcon } from '../../../src/svgs/cloudHostjane/cpanel.svg';
import { ReactComponent as DjangoIcon } from '../../../src/svgs/cloudHostjane/django.svg';
import { ReactComponent as DirectAdmin } from '../../../src/svgs/cloudHostjane/docker.svg';
import { ReactComponent as MultisiteIcon } from '../../../src/svgs/cloudHostjane/multisite.svg';
import { ReactComponent as NginxIcon } from '../../../src/svgs/cloudHostjane/nginx.svg';
import { ReactComponent as OvhIcon } from '../../../src/svgs/cloudHostjane/ovh.svg';
import { ReactComponent as PhpIcon } from '../../../src/svgs/cloudHostjane/php.svg';
import { ReactComponent as PleskIcon } from '../../../src/svgs/cloudHostjane/plesk.svg';
import { ReactComponent as Laravel } from '../../../src/svgs/cloudHostjane/laravel.svg';
import { ReactComponent as Jupyter } from '../../../src/svgs/cloudHostjane/jupyter.svg';
import { ReactComponent as Elementor } from '../../../src/svgs/cloudHostjane/elementor.svg';
import { ReactComponent as GoogleSiteKit } from '../../../src/svgs/cloudHostjane/googlesitekit.svg';

import { ReactComponent as SqlServerIcon } from '../../../src/svgs/cloudHostjane/sql-server.svg';
import { ReactComponent as WindowsIcon } from '../../../src/svgs/cloudHostjane/windows.svg';
import { ReactComponent as WooIcon } from '../../../src/svgs/cloudHostjane/woo.svg';
import { ReactComponent as WordPressIcon } from '../../../src/svgs/cloudHostjane/wordpress.svg';

export default {
	title: 'Managed cloud hosting—24/7 support by pros.',
	items: [
		{
			icon: AwsIcon,
			ecIcon: EC2Icon,
			ecShowIcon: true,
			title: 'WordPress Hosting',
			description:
				'Fully-managed Amazon EC2 hosting for WordPress sites',
			list: [
				'WP-Admin pass emailed by server on launch',
				'Free SSL cert configured to your domain',
				'Free server set up',
				'Start / stop / reboot, SSH, root access and firewall',
				'Amazon IP & datacenter',
				'From $22.95 /mo',
			],
			link: '/wordpress/',
			linkLabel: 'Get Started',
			internal: true,
			iconGroup: [
				<WordPressIcon key="WordPressIcon" />,
				<Elementor key="Elementor" />,
				<WooIcon key="WooIcon" />,
				<GoogleSiteKit key="GoogleSiteKit" />,
				<MultisiteIcon key="MultisiteIcon" />,
			],
		},
		{
			icon: AwsIcon,
			ecIcon: EC2Icon,
			ecShowIcon: true,
			title: 'Managed Compute',
			description:
				'Fully-managed AWS EC2 for business, dev, and data.',
			list: [
				'Free server set up',
				'Start / stop / reboot, SSH, root access and firewall',
				'Database credentials sent by server on launch',
				'Amazon IP & datacenter',
				'Free SSL cert configured to your domain',
				'From $22.95 /mo',
			],
			link: '/cloud/',
			linkLabel: 'Get Started',
			internal: true,

			iconGroup: [
				<Laravel key="Laravel" />,
				<Jupyter key="Jupyter" />,
				<PhpIcon key="PhpIcon" />,
				<NginxIcon key="NginxIcon" />,
				<DjangoIcon key="DjangoIcon" />,
			],
		},
		{
			icon: OvhIcon,
			ecIcon: EC2Icon,
			ecShowIcon: false,
			title: 'VPS Hosting',
			description:
				'AWS Virtual Private Servers on EC2 bare-metal compute.',
			list: [
				'Full VPS management, free set up, and monitoring',
				'Amazon IP & datacenter',
				'cPanel Admin credentials or Plesk one-time link emailed by server on launch',
				'Free SSL cert configured to your domain',
				'From $17.95 /mo',
			],
			link: '/vps-hosting/',
			linkLabel: 'Get Started',
			internal: true,
			iconGroup: [
				<DirectAdmin key="DirectAdmin" />,
				<CpanelIcon key="CpanelIcon" />,
				<PleskIcon key="PleskIcon" />,
			],
		},
		{
			icon: AwsIcon,
			ecIcon: EC2Icon,
			ecShowIcon: true,
			title: 'Windows Servers',
			description:
				'Fully-managed Amazon EC2 virtual machines for Windows Server or SQL Server.',
			list: [
				'Amazon IP & datacenter',
				'RDP access (Windows)',
				'Database credentials sent by server on launch',
				'Start / stop / reboot, SSH, root access and firewall',
				'Free server set up',
				'From $22.95 /mo',
			],
			link: '/windows/',
			linkLabel: 'Get Started',
			internal: true,
			iconGroup: [
				<WindowsIcon key="WindowsIcon" />,
				<SqlServerIcon key="SqlServerIcon" />,
			],
		},
	],
};
