export default {
	title: 'Host on the shoulders of a giant',
	description:
		'Run your website from 14 market-leading Amazon datacenters',
	buttons: [
		{
			label: 'Physical VPS',
			link: '/vps-hosting/#hostjane-buy-hosting',
			type: 'primary',
		},
		{
			label: 'Cloud Servers',
			link: '/cloud',
			type: 'secondary',
		},
	],
	continents: [
		'Africa',
		'Antarctica',
		'Asia',
		'Australia',
		'Europe',
		'North America',
		'Oceania',
		'South America',
	],
	markers: [
		// Region 1
		{
			label: 'US East (N. Virginia)',
			latitude: 38.8051,
			longitude: -77.0470,
			region: 'AMAZON CODE: US-EAST-1',
			comingSoon: false,
			usRegion: true,
		},
		{
			label: 'US East (Ohio)',
			latitude: 40.4173,
			longitude: -82.9071,
			region: 'AMAZON CODE: US-EAST-2',
			comingSoon: false,
			usRegion: true,
		},
		{
			label: 'US West (Oregon)',
			latitude: 43.8041,
			longitude: -120.5542,
			region: 'AMAZON CODE: US-WEST-2',
			comingSoon: false,
			usRegion: true,
		},
		{
			label: 'Canada (Montreal)',
			latitude: 45.5019,
			longitude: -73.5674,
			region: 'AMAZON CODE: CA-CENTRAL-1',
			comingSoon: false,
			usRegion: true,
		},
		// Region 2
		{
			label: 'Ireland (Dublin)',
			latitude: 53.7798,
			longitude: -7.3055,
			region: 'AMAZON CODE: EU-WEST-1',
			comingSoon: false,
			europeRegion: true,
		},
		{
			label: 'France (Paris)',
			latitude: 48.8566,
			longitude: 2.3522,
			region: 'AMAZON CODE: EU-WEST-3',
			comingSoon: false,
			europeRegion: true,
		},
		{
			label: 'UK (London)',
			latitude: 51.5074,
			longitude: -0.1276,
			region: 'AMAZON CODE: EU-WEST-2',
			comingSoon: true,
			europeRegion: true,
		},
		{
			label: 'Germany (Frankfurt)',
			latitude: 50.1109,
			longitude: 8.6821,
			region: 'AMAZON CODE: EU-CENTRAL-1',
			comingSoon: false,
			europeRegion: true,
		},
		{
			label: 'Sweden (Stockholm)',
			latitude: 59.3293,
			longitude: 18.0686,
			region: 'AMAZON CODE: EU-NORTH-1',
			comingSoon: false,
			europeRegion: true,
		},
		// Region 3
		{
			label: 'Japan (Tokyo)',
			latitude: 35.6764,
			longitude: 139.6500,
			region: 'AMAZON CODE: AP-NORTHEAST-1',
			comingSoon: false,
			asiaRegion: true,
		},
		{
			label: 'Republic of Korea (Seoul)',
			latitude: 37.5503,
			longitude: 126.9971,
			region: 'AMAZON CODE: AP-NORTHEAST-2',
			comingSoon: true,
			asiaRegion: true,
		},
		{
			label: 'Singapore',
			latitude: 1.3521,
			longitude: 103.8198,
			region: 'AMAZON CODE: AP-SOUTHEAST-1',
			comingSoon: false,
			asiaRegion: true,
		},
		{
			label: 'India (Mumbai)',
			latitude: 19.0760,
			longitude: 72.8777,
			region: 'AMAZON CODE: AP-SOUTH-1',
			comingSoon: false,
			asiaRegion: true,
		},
		// Region 4
		{
			label: 'Australia (Sydney)',
			latitude: -33.8688,
			longitude: 151.2093,
			region: 'AMAZON CODE: AP-SOUTHEAST-2',
			comingSoon: false,
			oceaniaRegion: true,
		},
	],

};
