import { svg01, svg02, svg03, svg04 } from './icons';

export default {
	items: [
		{
			icon: svg01,
			title: 'Find Freelancers',
			link: 'https://www.hostjane.com/marketplace/skills',
			internal: false,
		},
		{
			icon: svg02,
			title: 'Become a Seller',
			link: 'https://www.hostjane.com/sell',
			internal: true,
		},
		{
			icon: svg03,
			title: "Jane's Mission",
			link: 'https://www.hostjane.com/about',
			internal: true,
		},
		{
			icon: svg04,
			title: 'Community',
			link: 'https://www.hostjane.com/community',
			internal: true,
		},
	],
};
