const sectionIds = {
	Freelancers: 'hostjane-sellers',
	Workspace: 'hostjane-slider',
	'How it works': 'how-it-works',
	Hosting: 'hero',
	Solutions: 'explore',
	Mission: 'what-is-hostjane',
	Developers: 'service-slider1',
	Creatives: 'service-slider2',
	'Customer stories': 'testimonials',
	'Global Network': 'map',
	Innovation: 'features',
};

export default sectionIds;
